const getDefaultMenu = (query) => [
  {
    label: 'Home',
    items: [
      {
        label: 'Campaigns',
        icon: 'pi pi-fw pi-chart-line',
        to: { name: 'home', query },
      },
      {
        label: 'Bidding Optimisation',
        icon: 'pi pi-fw pi-sliders-v',
        items: [
          {
            label: 'Bidding Rules',
            icon: 'pi pi-fw pi-filter',
            to: { name: 'bidRules', query },
          },
          {
            label: 'Bidding Rules History',
            icon: 'pi pi-fw pi-history',
            to: { name: 'bidrulesHistory', query },
          },
        ],
      },
      {
        label: 'Settings',
        icon: 'pi pi-fw pi-cog',
        items: [
          {
            label: 'Metrics',
            icon: 'pi pi-fw pi-sliders-v',
            to: { name: 'metrics', query },
          },
          {
            label: 'Profiles',
            icon: 'pi pi-fw pi-users',
            to: { name: 'profiles', query },
          },
        ],
      },
    ],
  },
];

const getProfilesOnlyMenu = (query) => [
  {
    label: 'Home',
    items: [
      {
        label: 'Settings',
        icon: 'pi pi-fw pi-cog',
        items: [
          {
            label: 'Profiles',
            icon: 'pi pi-fw pi-users',
            to: { name: 'profiles', query },
          },
        ],
      },
    ],
  },
];

export { getDefaultMenu, getProfilesOnlyMenu };
