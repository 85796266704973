<template>
  <Card>
    <template #title>
      <h2>Profile selection</h2>
    </template>
    <template #content>
      <div>
        <p>Select the profiles you want to <strong>enable</strong> in your account.</p>
        <MultiSelect
          v-model="selectedProfiles"
          data-key="profileId"
          class="w-6"
          :options="profiles"
          option-value="profileId"
          placeholder="Select profiles to enable..."
          :option-label="getProfileName"
          :filter="true"
          display="chip"
          :selection-limit="getMaxProfiles()"
        >
          <template #option="slotProps">
            <span>{{ `${slotProps.option.name} - ${slotProps.option.countryCode}` }}</span><span class="entity-id">{{ `${slotProps.option.entityId.replace("ENTITY", "")}` }}</span>
          </template>
        </MultiSelect>
      </div>
    </template>
    <template #footer>
      <Button
        :loading="loadingProfiles"
        @click="updateEnabledProfiles"
      >
        Save
      </Button>
    </template>
  </Card>
  <Toast />
</template>

<script>
import { mapMutations } from 'vuex';
import A2CAClient from '../../../api/a2caAuthorizedClient';
import hasProfilesEnabled from '../../../utils/accountUtils';
import { getDefaultMenu, getProfilesOnlyMenu } from '../../../constants/menuConstants';

export default {
  inject: ['query'],
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    selectedProfile: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout'],
  data() {
    return {
      profiles: this.$store.state.auth.profiles,
      selectedProfiles: [...new Set(Object.entries(this.$store.state.auth.account.enabledProfiles).filter((idEntry) => idEntry[1]).map((idEntry) => idEntry[0]))],
      loadingProfiles: false,
    };
  },
  methods: {
    async updateEnabledProfiles() {
      this.loadingProfiles = true;
      const client = new A2CAClient(this.query);

      // Create request object
      const updateEnabledProfilesRequest = {};
      this.profiles.forEach((profile) => {
        updateEnabledProfilesRequest[profile.profileId] = false;
      });
      this.selectedProfiles.forEach((profileId) => {
        updateEnabledProfilesRequest[profileId] = true;
      });
      try {
        const response = await client.updateEnabledProfiles(updateEnabledProfilesRequest);

        this.$toast.add({
          severity: 'success',
          summary: 'Selected profiles saved',
          detail: 'Selected profiles have been saved.',
          life: 1500,
        });
        if (hasProfilesEnabled(response)) {
          this.$emit('change-menu-layout', getDefaultMenu(this.query));
        } else {
          this.$emit('change-menu-layout', getProfilesOnlyMenu(this.query));
        }

        await new Promise((r) => setTimeout(r, 500));

        let profile = response[this.selectedProfile?.profileId] ? this.profiles.find((p) => p.profileId === this.selectedProfile.profileId) : null;

        if (!profile) {
          const firstEnabled = Object.keys(response).find((key) => !!response[key]);
          profile = this.profiles.find((p) => p.profileId === firstEnabled) || null;
        }

        this.setEnabledProfiles(response);
        this.setSelectedProfile({ profile, useSession: !!this.query?.mockAccountId });
        this.$emit('clear-selected-profile');
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Selected profiles could not be saved.',
          detail: 'Selected profiles could not be saved. An error has occured.',
          life: 1500,
        });
        console.error(err);
      }

      this.loadingProfiles = false;
    },
    getProfileName(profile) {
      return `${profile.name} - ${profile.countryCode} - ${profile.entityId.replace('ENTITY', '')}`;
    },
    getMaxProfiles() {
      return this.$store.state.auth.maxProfiles;
    },
    ...mapMutations(['setEnabledProfiles', 'setSelectedProfile']),
  },

};
</script>
<style scoped>
  .entity-id {
    color: gray;
    font-size: 12px;
    margin-left: 7px;
    margin-top: 2px;
  }
</style>
